import React,{ useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate } from "react-router-dom"
import logo from './logo.png';
import ConfirmDialog from "../components/Confirm";

const Navbar = () => {
  // State to manage the navbar's visibility
  const navigate = useNavigate();  
  const [user, setUser] = useState({});
  const [confirmOpen, setConfirmOpen] = useState([]); 

  useEffect(()=>{
    if(localStorage.getItem('token') != "" || localStorage.getItem('token') != null){
        getUser();   
        setConfirmOpen(false)  
    }
  },[])

  const getUser = () => {
    axios.get('/api/driver/user', { headers:{Authorization: 'Bearer ' + localStorage.getItem('token')}})
    .then((r) => {
       if(r.data.user) 
            setUser(r.data.user);
       else
            localStorage.setItem('token', "");
     //  console.log(r.data)
    })
    .catch((e) => {
        localStorage.setItem('token', "");           
    });
 }
 const logoutAction = () => {
  axios.post('/api/driver/logout',{}, { headers:{Authorization: 'Bearer ' + localStorage.getItem('token')}})
  .then((r) => {
      localStorage.setItem('token', "")
     navigate("/");
  })
  .catch((e) => {
      localStorage.setItem('token', "")
      navigate("/");
      console.log(e)
  });
}    

  return (    
     <header className="bg-blue-600 relative z-20 px-6">
			<div className="container mx-auto flex justify-between items-center relative h-24">

				<a href="/" className="w-16 md:w-48 sm:w-32 h-full inline-block py-4 flex items-center font-black text-xl">
          <img src={logo} className="App-logo object-cover" alt="logo" />            
				</a>
        
				{user.name ? 
				    <ul className="md:flex flex h-full justify-end items-center text-lg font-semibold">                
                        <li className="ml-3">
                            Welcome, {user.name ? user.name : ''}!
                        </li>  
                    </ul> :
                    <ul className="md:flex flex h-full justify-end items-center text-lg font-semibold">
                        <li className="ml-3"><Link to="/">Login</Link></li>
                        <li className="ml-3"><Link to="/register">Register</Link></li>	
                    </ul>
         } 
			
				<div className="absolute bottom-0 right-0 -mb-5 flex">		
        {user.name ? 	
          <a className="bg-gray-500 text-white py-2 px-4 text-xs md:text-sm rounded-full ml-3 hover:shadow-lg" 
            onClick={                        
              () => { setConfirmOpen(true); }} aria-current="page" href="#">Logout</a>		
          
          :
            <Link className="bg-gray-500 text-white py-2 px-4 text-xs md:text-sm rounded-full ml-3 hover:shadow-lg" to="/contacts">Contact Us</Link>
        }
                  <ConfirmDialog
                      title="Logout"
                      open={confirmOpen}
                      onClose={() => setConfirmOpen(false)}
                      onConfirm={() => logoutAction()}
                  >
                      Do you really want to logout?
                  </ConfirmDialog>                
				</div>
			</div>
		</header>

  );
};

export default Navbar;