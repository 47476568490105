import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Login from "./pages/Login"
import Register from "./pages/Register"
import Dashboard from "./pages/Dashboard"
import Approved from "./pages/Approved"
import Today from "./pages/Today"
import Upcoming from "./pages/Upcoming"
import Sendcode from "./pages/Sendcode"
import Resetpwd from "./pages/Resetpwd"
import Contacts from "./pages/Contacts"
import Profile from "./pages/Profile" 
import Allocation from "./pages/Allocation" 
import  Resetlivepwd from "./pages/Resetlivepwd" 
  
function App() {
  return (
    <Router>
      <Routes>
          <Route exact path="/"  element={<Login/>} />
          <Route path="/register"  element={<Register/>} />
          <Route path="/dashboard"  element={<Dashboard/>} /> 
          <Route path="/approved"  element={<Approved/>} />  
          <Route path="/today"  element={<Today/>} />  
          <Route path="/upcoming"  element={<Upcoming/>} />           
          <Route path="/sendcode"  element={<Sendcode/>} />
          <Route path="/resetpassword"  element={<Resetpwd/>} />
          <Route path="/contacts"  element={<Contacts/>} />
          <Route path="/profile"  element={<Profile/>} />
          <Route path="/allocation"  element={<Allocation/>} />
          <Route path="/resetlivepwd"  element={<Resetlivepwd/>} />         
      </Routes>
    </Router>
  );
}
  
export default App;