import React,{ useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate } from "react-router-dom"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
  
function Profile() {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    
    useEffect(()=>{
        if(localStorage.getItem('token') != "" || localStorage.getItem('token') != null){
            getUser();    
        }
    },[])
 
    const getUser = () => {
        axios.get('/api/driver/user', { headers:{Authorization: 'Bearer ' + localStorage.getItem('token')}})
        .then((r) => {
           if(r.data.user) 
                setUser(r.data.user);
           else
                localStorage.setItem('token', "");
         //  console.log(r.data)
        })
        .catch((e) => {
            localStorage.setItem('token', "");           
        });
    }
    
         
    return (
        <Layout>
        <Navbar/>
        <div className="bg-white-100 pt-8 px-2"> 
            <div className="container mx-auto">
                <div className="w-full">
                    <div className="w-full lg:w-1/6">
                        <Link to="/dashboard" className="w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-grey-900 border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:text-white hover:bg-gray-500 dark:text-gray-200 dark:border-gray-700">
                            <strong> BACK TO HOME </strong>
                            <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180 mt-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                            </svg>
                        </Link>                                
                    </div>
                </div>
            </div>   
        </div>  

            <div className="flex min-h-full flex-1 flex-col justify-center px-6 pb-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">                   
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Profile
                    </h2>
                </div>
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">                                               
                        <div className="flex items-center justify-between text-center">
                                                      
                        </div> 
                        <div className="items-center justify-between text-center">
                            {user.name}                              
                        </div> 
                        <div className="items-center justify-between text-center">                                
                            {user.phone}                                                     
                        </div>
                        <div className="items-center justify-between text-center">                                
                            {user.email}                                                     
                        </div>     
                  
                </div>
            </div>            
            <Footer/>
        </Layout>
    );
}
   
export default Profile;