import React,{ useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useNavigate } from "react-router-dom"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar";
import Footer from "../components/Footer"
import logo from '../components/logo.png';

// toast-configuration method,
// it is compulsory method.
//toast.configure({autoClose: 2000});
  
function Dashboard() {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [todaydata, setTodaydata] = useState([]);    
     
    useEffect(()=>{
        if(localStorage.getItem('token') == "" || localStorage.getItem('token') == null){
            navigate("/");
        }else {           
            getUser();          
            getTodaydata();
        }
    },[])
 
    const getUser = () => {
        axios.get('/api/driver/user', { headers:{Authorization: 'Bearer ' + localStorage.getItem('token')}})
        .then((r) => {
           if(r.data.user) 
                setUser(r.data.user);
           else
                localStorage.setItem('token', "");
         //  console.log(r.data)
        })
        .catch((e) => {
            localStorage.setItem('token', "");
            navigate("/");
            console.log(e)
        });
    }
    let cars = [{economy: "ECONOMY SEDAN", economy_van: "ECONOMY VAN", premium: "PREMIUM SEDAN", luxury: "LUXURY SEDAN ", suv: "PREMIUM SUV", premium_van: "PREMIUM VAN", luxury_van: "LUXURY VAN", mini_bus: "MINI BUS", strech_limo: "STRECH LIMO", hummer: "HUMMER STRECH LIMO"}];
    
    const getTodaydata = () => {        
        //  setAprovedata(cars)  
          axios.get('/api/driver/todaydata', { headers:{Authorization: 'Bearer ' + localStorage.getItem('token')}})
          .then((r) => {
            setTodaydata(r.data.todaydata)                     
          })
          .catch((e) => {
              console.log(e)
          }); 
      }
 
    const logoutAction = () => {
        axios.post('/api/driver/logout',{}, { headers:{Authorization: 'Bearer ' + localStorage.getItem('token')}})
        .then((r) => {
            localStorage.setItem('token', "")
           navigate("/");
        })
        .catch((e) => {
            localStorage.setItem('token', "")
            navigate("/");
            console.log(e)
        });
    }

        
    return (
        <Layout>
        <Navbar/>
        <div className="bg-white-100 py-8 px-2">
            <div className="container mx-auto" key="bin2">
                <div className="flex flex-wrap sm:items-center"> 
                
                    {/* start Render the fetched approved data */}
                    {  todaydata.map((adata) => (	
                        <div className="w-full p-4 m-4 border border-gray-900" key={adata.id}>   
                       
                            <p className="text-sm md:text-base font-condensed mx-auto">
                                Your trip today is from {adata.pick_location} to {adata.drop_location}, at {adata.pick_time}.                                 
                            </p>                            
                        </div>
                     )) }
                   
                    {/* end Render the fetched approved data */}  
                </div>    
                <div className="mx-auto sm:w-full max-w-sm flex flex-wrap"> 
                        <Link className="rounded-2xl hover:bg-gray-500 m-4 cursor-pointer bg-blue-600 text-white text-center box-border h-24 w-24 px-2 py-4 border-4 m4 hover:shadow-lg" to="/upcoming">Upcoming Trips</Link>        
                        <Link className="rounded-2xl hover:bg-gray-500 m-4 cursor-pointer bg-blue-600 text-white text-center box-border h-24 w-24 p-4 border-4 m4 hover:shadow-lg" to="/approved">My Trips</Link>        
                        <Link className="rounded-2xl hover:bg-gray-500 m-4 cursor-pointer bg-blue-600 text-white text-center box-border h-24 w-24 px-2 py-4 border-4 m4 hover:shadow-lg" to="/allocation">Trip Allocation</Link>                                            
                        <Link className="rounded-2xl hover:bg-gray-500 m-4 cursor-pointer bg-blue-600 text-white text-center box-border h-24 w-24 p-4 border-4 m4 hover:shadow-lg" to="/resetlivepwd">Reset Password</Link>
                        <Link className="rounded-2xl hover:bg-gray-500 m-4 cursor-pointer bg-blue-600 text-white text-center box-border h-24 w-24 p-4 border-4 m4 hover:shadow-lg" to="/profile">Profile</Link>  
                        <Link className="rounded-2xl hover:bg-gray-500 m-4 cursor-pointer bg-blue-600 text-white text-center box-border h-24 w-24 p-4 border-4 m4 hover:shadow-lg" to="/contacts">Contact Us</Link>                        
                  
                </div>                
            </div>            
        </div> 
           
            <Footer/>
        </Layout>
    );
}
   
export default Dashboard;