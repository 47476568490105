import React,{ useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useNavigate } from "react-router-dom"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"

// toast-configuration method,
// it is compulsory method.
//toast.configure({autoClose: 2000});
  
function Approved() {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [aprovedata, setAprovedata] = useState([]);
    

  
    useEffect(()=>{
        if(localStorage.getItem('token') == "" || localStorage.getItem('token') == null){
            navigate("/");
        }else {           
            getUser();           
            getApprovedata();
        }
    },[])
 
    const getUser = () => {
        axios.get('/api/driver/user', { headers:{Authorization: 'Bearer ' + localStorage.getItem('token')}})
        .then((r) => {
           if(r.data.user) 
                setUser(r.data.user);
           else
                localStorage.setItem('token', "");
         //  console.log(r.data)
        })
        .catch((e) => {
            localStorage.setItem('token', "");
            navigate("/");
            console.log(e)
        });
    }
    let cars = [{economy: "ECONOMY SEDAN", economy_van: "ECONOMY VAN", premium: "PREMIUM SEDAN", luxury: "LUXURY SEDAN ", suv: "PREMIUM SUV", premium_van: "PREMIUM VAN", luxury_van: "LUXURY VAN", mini_bus: "MINI BUS", strech_limo: "STRECH LIMO", hummer: "HUMMER STRECH LIMO"}];
    
    const getApprovedata = () => {        
        //  setAprovedata(cars)  
          axios.get('/api/driver/approvedata', { headers:{Authorization: 'Bearer ' + localStorage.getItem('token')}})
          .then((r) => {
            setAprovedata(r.data.approvedata)                     
          })
          .catch((e) => {
              console.log(e)
          }); 
      }
 
    const logoutAction = () => {
        axios.post('/api/driver/logout',{}, { headers:{Authorization: 'Bearer ' + localStorage.getItem('token')}})
        .then((r) => {
            localStorage.setItem('token', "")
           navigate("/");
        })
        .catch((e) => {
            localStorage.setItem('token', "")
            navigate("/");
            console.log(e)
        });
    }    
     
    return (
        <Layout>
        <Navbar/>        
        <div className="bg-white-100 py-8 px-2">
            <div className="container mx-auto">
                <div className="w-full">
                    <div className="w-full lg:w-1/6">
                        <Link to="/dashboard" className="w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-grey-900 border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:text-white hover:bg-gray-500 dark:text-gray-200 dark:border-gray-700">
                            <strong> BACK TO HOME </strong>
                            <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180 mt-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                            </svg>
                        </Link>                                
                    </div>
                </div>
                <div className="flex flex-wrap">  
                
                    {/* start Render the fetched approved data */}
                    {  aprovedata.length > 0 ? aprovedata.map((tdata) => (	
                        <div className="w-full max-w-sm border border-gray-400 mt-2 mr-2" key="tdata.id">
                        <ul className="py-6 mb-2">
							<li className="items-center mb-2">
								
								<div className="pl-3">
									<h4 className="text-base md:text-lg text-gray-900 leading-tight mb-2">
                                        Pick Date & Time: {tdata.pick_date}, {tdata.pick_time}
                                    </h4>
									<p className="text-xs md:text-sm text-gray-700 leading-snug max-w-sm">
                                        <strong>Pick Location: </strong>{tdata.pick_location}
                                    </p>    
                                    <p className="text-xs md:text-sm text-gray-700 leading-snug max-w-sm">
                                        <strong>Drop Location: </strong>{tdata.drop_location}

                                    </p>
								</div>
							</li>
                            <li className="items-center mb-2">
								
								<div className="pl-3">
									<h4 className="text-base md:text-lg text-gray-900 leading-tight mb-2">
                                        Passenger Details
                                    </h4>
									<p className="text-xs md:text-sm text-gray-700 leading-snug max-w-sm">
                                        <strong>Name: </strong>{tdata.travel_name}
                                    </p>    
                                    <p className="text-xs md:text-sm text-gray-700 leading-snug max-w-sm">
                                        <strong>Phone: </strong>{tdata.travel_phone}

                                    </p>
								</div>
							</li>
							<li className="items-center mb-2">								
								<div className="pl-3">
									<h4 className="text-base md:text-lg text-gray-900 leading-tight mb-2">Price & Car Class </h4>
									<p className="text-xs md:text-sm text-gray-700 leading-snug max-w-sm">
                                        <strong>${tdata.driver_price}</strong>, {cars[0][tdata.car_type]}. 
                                    </p>
								</div>
							</li>
							<li className="items-center mb-2">							
								<div className="pl-3">
									<h4 className="text-base md:text-lg text-gray-900 leading-tight mb-2">Traveller</h4>
									<p className="text-xs md:text-sm text-gray-700 leading-snug max-w-sm">
                                        <strong>Adults: </strong>{tdata.no_adults}
                                    </p>
                                    <p className="text-xs md:text-sm text-gray-700 leading-snug max-w-sm">
                                        <strong>Children: </strong>{tdata.no_children}
                                    </p>
                                    <p className="text-xs md:text-sm text-gray-700 leading-snug max-w-sm">
                                        <strong>Infants: </strong>{tdata.no_infants}
                                    </p>
								</div>
							</li>
                            <li className="items-center mb-2">								
								<div className="pl-3">
									<h4 className="text-base md:text-lg text-gray-900 leading-tight mb-2">Additional</h4>
									<p className="text-xs md:text-sm text-gray-700 leading-snug max-w-sm">
                                        <strong>Luggage Trailer: </strong> {tdata.luggage_trailer == 1 ? 'Yes' : 'No'}
                                    </p>
                                    <p className="text-xs md:text-sm text-gray-700 leading-snug max-w-sm">
                                        <strong>{tdata.no_infants > 0 ?  'Child Seat: ' + tdata.no_infants : ''}</strong> 
                                    </p>                                   
								</div>
							</li>
                            <li className="items-center mb-2">							
								<div className="pl-3">
									<h4 className="text-base md:text-lg text-gray-900 leading-tight mb-2">Driver Details</h4>
									<p className="text-xs md:text-sm text-gray-700 leading-snug max-w-sm">
                                        <strong>Name: </strong>{tdata.driver_name}
                                    </p>
                                    <p className="text-xs md:text-sm text-gray-700 leading-snug max-w-sm">
                                        <strong>Phone: </strong>{tdata.driver_phone}
                                    </p>
                                   
								</div>
							</li>
						</ul>                                              
                    </div>
                     )) : (
                        <div className="max-w-xl mx-auto text-center mt-5">
                            <h4 className="font-black text-base md:text-sm text-gray-900 leading-tight mb-2">Sorry! There are no trips planned for the coming days.</h4>				
                        </div>           
                    ) }
                    {/* end Render the fetched approved data */}
                  
                </div>
            </div>
        </div> 
           
            <Footer/>
        </Layout>
    );
}
   
export default Approved;