import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import logo from './footer_logo.png';

const Footer =() =>{
    return(       
        <div className="bg-gray-200 px-6 py-8">			
			<footer className="container mx-auto">

				<div className="flex flex-col md:flex-row">
					<div className="md:w-1/4 flex flex-col md:mb-0">
						<div className="sm:max-w-sm">
							<h4 className="font-condensed pb-4">PHONE: + 61 403 230 255</h4>	
							<a href="/" className="w-64 inline-block py-2 flex items-center font-black text-xl">
        						<img src={logo} className="App-logo object-cover" alt="logo" />            
							</a>
													
						</div>						
					</div>
					<div className="md:w-1/4 flex flex-col md:mb-0">
						<h4 className="font-condensed pb-4">MPT</h4>
						<p className="text-sm text-gray-800">My Private Transfers Brisbane</p>
						<p className="text-sm text-gray-800">86 Brookes St, Fortitude Valley QLD 4006</p>
						<p className="text-sm text-gray-800">phone: 1300 494 997</p>
					</div>
					<div className="md:w-1/4 flex flex-col md:mb-0">
						<h4 className="font-condensed pb-4">LLC</h4>
						<p className="text-sm text-gray-800">Luxury Limousine Chauffeurs Brisbane</p>
						<p className="text-sm text-gray-800">3/85 Stevenson St, Ascot QLD 4007</p>						
						<p className="text-sm text-gray-800">phone: 1300 011 077</p>
					</div>
					<div className="md:w-1/4 flex flex-col md:mb-0">
						<h4 className="font-condensed pb-4">ESQ</h4>
						<p className="text-sm text-gray-800">Esquire Airport Transfers</p>
						<p className="text-sm text-gray-800">1 Daintree Rd Brisbane Airport QLD 4008</p>
						<p className="text-sm text-gray-800">phone: +61 403 230 255</p>
						<p className="text-sm text-gray-800">phone: +61 420 399 279</p>
					</div>
				</div>

				<div className="flex justify-between items-end">
				<div className="w-full md:w-1/4">
						<div>							
							<p className="text-xs text-gray-700 mt-2">Designed and Developed by <a href="http://www.excellone.com.au/" target="blank">excellone.com.au</a></p> 
                            
						</div>
					</div>
				</div>

			</footer>
		</div>
    )
}
   
export default Footer;