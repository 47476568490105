import React from 'react' 
import Dialog from './Dialog';
  
    function Confirm(props) {
        const { open, onClose, title, children, onConfirm } = props;
        if (!open) {
          return <></>;
        }
        
        return (
          <Dialog open={open} onClose={onClose}>
            <h2 className="text-xl">{title}</h2>
            <div className="py-5">{children}</div>
            <div className="flex justify-end">
            <div className="p-2 text-xl bg-blue-600 text-white rounded-md">
                <button
                  onClick={() => {
                    onClose();
                    onConfirm();
                  }}
                >
                  OK
                </button>
              </div>  
              <div className="p-2 text-xl ml-6 bg-gray-600 text-white rounded-md">
                <button
                  onClick={() => onClose()}
                  className="bg-secondary hover:bg-secondary-light"
                >
                  Cancel
                </button>
              </div>
              
             
            </div>
          </Dialog>
        );
       }
     
       export default Confirm;