import React,{ useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate } from "react-router-dom"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
  
function Resetlivepwd() {
    const navigate = useNavigate(); 
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [validationErrors, setValidationErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [msg, setMsg] = useState([""]);
    const [user, setUser] = useState({});
 
    useEffect(()=>{
        if(localStorage.getItem('token') != "" || localStorage.getItem('token') != null){
            getUser();    
        }
    },[])
 
    const getUser = () => {
        axios.get('/api/driver/user', { headers:{Authorization: 'Bearer ' + localStorage.getItem('token')}})
        .then((r) => {
           if(r.data.user) 
                setUser(r.data.user);
           else
                localStorage.setItem('token', "");
         //  console.log(r.data)
        })
        .catch((e) => {
            localStorage.setItem('token', "");           
        });
    }
 
    const resetpswAction = (e) => {
        e.preventDefault();
        setIsSubmitting(true)
        let payload = {  
            password:password,
            password_confirmation:confirmPassword
        }
        axios.post('/api/driver/passwordchange', payload, { headers:{Authorization: 'Bearer ' + localStorage.getItem('token')}})
        .then((r) => {
            setIsSubmitting(false)
          //  localStorage.setItem('token', r.data.token)
            navigate("/dashboard");
        })
        .catch((e) => {
            setIsSubmitting(false)
          //  console.log(e.response);
            if (e.response.data.errors != undefined) {  
                setValidationErrors(e.response.data.errors);
            }
        });
    }
     
    return (
        <Layout>
             <Navbar/>
            <div className="bg-white-100 pt-8 px-2"> 
            <div className="container mx-auto">
                <div className="w-full">
                    <div className="w-full lg:w-1/6">
                        <Link to="/dashboard" className="w-full flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-grey-900 border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:text-white hover:bg-gray-500 dark:text-gray-200 dark:border-gray-700">
                            <strong> BACK TO HOME </strong>
                            <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180 mt-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                            </svg>
                        </Link>                                
                    </div>
                </div>
            </div>   
            </div>  
             <div className="flex min-h-full flex-1 flex-col justify-center px-6 pb-10 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm"> 
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Reset Password
                    </h2>
                </div>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <h4 className="text-base md:text-sm text-red-900 leading-tight" key="msg1">{msg}</h4>
                    <p>&nbsp;</p>
                    <form className="space-y-6" onSubmit={(e)=>resetpswAction(e)}> 
                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    New Password
                                </label>                               
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(e)=>{setPassword(e.target.value)}}
                                    required
                                    className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="confirm_password" className="block text-sm font-medium leading-6 text-gray-900">
                                New Confirm Password
                                </label>                               
                            </div>                                  
                                    <input                                                                           
                                        id="confirm_password"
                                        name="confirm_password"
                                        type="password"      
                                        value={confirmPassword}
                                        onChange={(e)=>setConfirmPassword(e.target.value)}
                                        required
                                        className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                            </div>
                        <div>
                            <button
                                disabled={isSubmitting}
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                            Reset Password
                            </button>
                            <p className="text-center">Don't have Token? <Link to="/sendcode" className="font-semibold text-indigo-600 hover:text-indigo-500">Forgot password</Link></p>
                        </div>                                
                    </form>
                </div>
            </div>
            <Footer/> 
        </Layout>
    );
}
   
export default Resetlivepwd;