import React from 'react' 
  
    function Dialog(props) {
   // const { open, onClose } = props;
        const  open = props.open;
        const onClose = props.onClose;
        if (!open) {
            return <></>;
        }  return (
            <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
                <div className="border border-gray-900 relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
                    <div>{props.children}</div>
                    <span className="absolute top-0 right-0 p-4">           
                        <button onClick={() => onClose()}> X </button>
                    </span>
                </div>
            </div>
        );
  }

  export default Dialog;